@import "bootstrap/bootstrap-colors";

mark {
    position: relative;
    background: inherit;
    color: inherit;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 4%;
        width: 92%;
        height: 38%;
        background: var(--ndl-primary);
        z-index: -1;
    }

    @each $color, $value in $theme-colors {
        &.mark-#{$color}:before {
            background: $value;
        }
    }
}
