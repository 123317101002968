// Colors
$ndl-white-blue: #ebf1fa;
$ndl-blue: #0FA1F8;
$ndl-dark-blue: #5886C3;
$ndl-extra-dark-blue: #0b1939;

$ndl-white: #FFFFFF;
$ndl-extra-light-grey: #F9F9F9;
$ndl-light-grey: #F0F0F3;
$ndl-grey: #B4BCC3;
$ndl-dark-grey: #83889E;
$ndl-black: rgba(black, 0.87);

$ndl-extra-light-green: #E6F8F1;
$ndl-light-green: #1AC37B;
$ndl-green: #08BD6F;
$ndl-dark-green: #079A5C;

$ndl-light-red: #E86368;
$ndl-red: #f63434;

// Tags
$ndl-pink: #DC6788;
$ndl-purple: #6771DC;
$ndl-orange: #F2994A;
$ndl-yellow: #F2C94C;
