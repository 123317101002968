@use "variables/ndl-sizes" as sizes;
@use "ndl-theming" as theming;
@use "variables/ndl-colors" as colors;

.ndl-tag {
    display: inline-block;
    max-width: 100%;

    &:not(:last-of-type) {
        margin-right: sizes.$ndl-margin-sm;
    }

    .mat-chip.mat-standard-chip {
        margin: 0;
        padding: sizes.pxToRem(5) sizes.$ndl-padding-xs;
        max-width: 100%;

        &::after {
            background: none;
        }

        @include theming.ndl-block-theme("tag");

        &.ndl-tag-default {
            background-color: colors.$ndl-light-grey;
        }
    }

    .mat-chip-list-wrapper {
        margin: inherit!important;
    }
}
