@use "ndl-sizes" as sizes;

$nunito: Nunito, Arial, sans-serif;

// Text sizes
$defaultFontSize: sizes.$ndl-text-xs;
$smallFontSize: sizes.$ndl-text-xxs;

// Titles
$h1FontSize: sizes.$ndl-text-xxl;
$h1LineHeight: sizes.$ndl-text-xxl * 1.5;
$h2FontSize: sizes.$ndl-text-xl;
$h2LineHeight: sizes.$ndl-text-xl * 1.2;
$h3FontSize: sizes.$ndl-text-lg;
$h3LineHeight: sizes.$ndl-text-lg * 1.33;
$h4FontSize: sizes.$ndl-text-md;
$h4LineHeight: sizes.$ndl-text-md * 1.25;
$h5FontSize: sizes.$ndl-text-sm;
$h5LineHeight: sizes.$ndl-text-sm * 1.25;
$h6FontSize: sizes.$ndl-text-xs;
$h6LineHeight: sizes.$ndl-text-xs * 1.25;
$mainHeadingFontSize: $h3FontSize;
$subHeadingFontSize: $h4FontSize;

// Line Heights
$defaultLineHeight: 1.42857;
$smallLineHeight: 1.5;
$subHeadingLineHeight: 1.2;
$mainHeadingLineHeight: 1.2;

// Font Weight
$defaultFontWeight: 400;
$smallFontWeight: 400;
$h2FontWeight: 500;
$h1FontWeight: 500;
$subHeadingFontWeight: 500;
$mainHeadingFontWeight: 500;

@mixin ndl-h1 {
    font-size: $h1FontSize;
    line-height: $h1LineHeight;
    font-weight: 600;
}
@mixin ndl-h2 {
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-weight: 600;
}
@mixin ndl-h3 {
    font-size: $h3FontSize;
    line-height: $h3LineHeight;
    font-weight: 600;
}
@mixin ndl-h4 {
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-weight: 600;
}
@mixin ndl-h5 {
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    font-weight: 600;
}
@mixin ndl-h6 {
    font-size: $h6FontSize;
    line-height: $h6LineHeight;
    font-weight: 600;
}
@mixin ndl-small {
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
}
