@import "variables/ndl-colors";
@import "variables/ndl-inputs";
@import "variables/ndl-sizes";

.ndl-form-field {
    caret-color: var(--ndl-primary);
}
.ndl-upload {
    .ndl-upload-field {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-weight: 600;

        .ndl-upload-auto-limit-hint {
            margin: 0;
            color: $ndl-dark-grey;
            font-size: pxToRem(10);
        }

        &.ndl-upload-field-empty, a {
            cursor: pointer;
        }

        .ndl-upload-field-placeholder {
            flex-grow: 1;
        }

        .ndl-upload-field-input {
            display: none;
        }

        .ndl-upload-field-content {
            flex-grow: 1;

            .ndl-upload-field-content-filenames {
                margin-bottom: $ndl-margin-xxs;
            }
        }
    }
}
