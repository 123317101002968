@import "../../variables/ndl-colors";

/**
 * This palette was generated with the tool provided by Material Design spec team:
 * https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 */
$ndl-green-50: #e2f6ea;
$ndl-green-100: #bae7cb;
$ndl-green-200: #8bd8aa;
$ndl-green-300: #54c988;
$ndl-green-400: $ndl-green;
$ndl-green-500: #00b155;
$ndl-green-600: #00a24b;
$ndl-green-700: #00903e;
$ndl-green-800: #007e33;
$ndl-green-900: #005f1e;

$ndl-green-palette : (
    50: $ndl-green-50,
    100: $ndl-green-100,
    200: $ndl-green-200,
    300: $ndl-green-300,
    400: $ndl-green-400,
    500: $ndl-green-500,
    600: $ndl-green-600,
    700: $ndl-green-700,
    800: $ndl-green-800,
    900: $ndl-green-900,
    A100: $ndl-green-100,
    A200: $ndl-green-200,
    A400: $ndl-green-400,
    A700: $ndl-green-700,
    contrast: (
        50: $ndl-extra-dark-blue,
        100:  $ndl-extra-dark-blue,
        200:  $ndl-extra-dark-blue,
        300:  $ndl-extra-dark-blue,
        400:  $ndl-extra-dark-blue,
        500:  $ndl-extra-dark-blue,
        600:  $ndl-extra-dark-blue,
        700:  $ndl-extra-dark-blue,
        800:  $ndl-white,
        900:  $ndl-white,
        A100:  $ndl-extra-dark-blue,
        A200:  $ndl-extra-dark-blue,
        A400:  $ndl-extra-dark-blue,
        A700:  $ndl-extra-dark-blue,
    )
);
