@use "variables/ndl-sizes" as sizes;

.ndl-alert {
    text-align: center;
    margin-bottom: sizes.$ndl-margin-xs;

    .ndl-alert-title {
        justify-content: center;
        margin-bottom: 0;
    }
}
