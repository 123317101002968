// Overriding Bootstrap breakpoints
$container-max-widths: (
    sm: 720px,
    md: 1100px,
    lg: 1200px,
    xl: 1280px
);

$grid-breakpoints: (
    xs: 0,
    sm: 700px,
    md: 1000px,
    lg: 1200px,
    xl: 1440px
);
