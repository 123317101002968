@use "sass:math";
@use "ndl-typography" as typo;
@use "ndl-sizes" as sizes;
@use "ndl-colors" as colors;

$inputPaddingX: sizes.$ndl-padding-md;
$inputPaddingY: sizes.$ndl-padding-sm;
$inputFontSize: typo.$defaultFontSize;
$inputLineHeight: typo.$defaultLineHeight;
$inputFontWeight: typo.$defaultFontWeight;
$captionMarginBottom: 0.285714rem;
$captionFontWeight: 600;
$inputWrapperMarginTop: $inputLineHeight + $captionMarginBottom;
$inputButtonsSize: 1.714285rem;
$inputIconSize: $inputButtonsSize;
$inputFixBottom: math.div($inputPaddingY * 2 + $inputLineHeight - $inputButtonsSize, 2);
$inputBorderRadius: sizes.$ndl-radius-xs;
$inputUploadBorderRadius: sizes.$ndl-padding-md;
$inputUploadPreviewSize: 5.71428rem;
