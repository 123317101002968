@use "variables/ndl-sizes" as sizes;
.ndl-tabs {
    .mat-tab-group {
        width: 100%;
    }
    .mat-tab-label-active:not(.mat-tab-disabled) {
        opacity: 1!important;
    }
    .mat-tab-label-content {
        font-size: sizes.$ndl-text-sm;

        @media only screen and (max-width: 767px) {
            font-size: sizes.$ndl-text-xxs;
        }
    }
}
