@import 'bootstrap-override/wb-variables';
@import 'ndl-variables';

.wb-pagination-control {
  text-align: center;

  margin-top: 30px;

  pagination-controls {
    ul.ngx-pagination {
      display: inline-flex;
      align-items: center;

      li {
        font-weight: 500;
        font-size: 13px;
        color: #7F8D9A;
        letter-spacing: 0;
        text-align: right;
      }

      li:not(.small-screen) {
        height: 28px;
        width: 28px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 10px;
        transition: color $m-controls;

        &:hover {
          color: black;
        }

        &.current {
          background: #17A0F8;
          cursor: default;
          color: white;
        }

        a {
          background: transparent;
          color: inherit;

          &:hover {
            background: transparent;
            color: inherit;
          }
        }

      }

      li.pagination-next {
        display: inline-block;

        &.disabled {
          display: none;
          transition: color $m-controls;

          &:hover {
            color: #7F8D9A;
          }

          &:after {
            font-family: 'icon';
            content: '\e91c';
            display: inline-block;
            font-size: 18px;
            font-weight: 800;
          }
        }

        a {
          display: flex;
          align-items: center;

          &:after {
            font-family: 'icon';
            content: '\e91c';
            display: inline-block;
            font-size: 18px;
            font-weight: 800;
          }
        }
      }

      li.pagination-previous {
        display: inline-block;

        &.disabled {
          display: none;
          transition: color $m-controls;

          &:hover {
            color: #7F8D9A;
          }

          &:before {
            font-family: 'icon';
            content: '\e91c';
            transform: rotate(180deg);
            display: inline-block;
            font-size: 18px;
            font-weight: 800;
          }
        }

        a {
          display: flex;
          align-items: center;

          &:before {
            font-family: 'icon';
            content: '\e91c';
            transform: rotate(180deg);
            display: inline-block;
            font-size: 18px;
            font-weight: 800;
          }
        }
      }

      li.pagination-previous, li.pagination-next {
        height: inherit;
        width: inherit;
        background: inherit;
      }

    }
  }

}

@media (max-width: 700px) {
  .wb-pagination-control {
    margin-top: 0;
    width: 100%;
    padding-bottom: 10px;

    pagination-controls {
      ul.ngx-pagination {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        li:not(.small-screen) {
          display: none;
        }

        .small-screen {
          display: inline-flex;
          margin: auto;
          flex: 1 1;
          text-align: center;
          justify-content: center;
        }

        .pagination-next {
          display: inline-flex !important;
          flex: 1 1;
          justify-content: flex-end !important;
        }

        .pagination-previous {
          display: inline-block !important;
          flex: 1 1;
          text-align: left;

        }

      }
    }

  }
}

.needl-pagination-control {
    pagination-controls {
        ul {
            &.ngx-pagination {
                li {
                    &:not(.small-screen) {
                        border-radius: 5px;
                        border: 1px solid #858C9D;

                        &.current {
                            background: $ndl-green;
                            border: none;
                        }

                        a {
                            span {
                                color: #000000;
                                margin-top: 3px;
                                display: inline-block;
                            }
                        }
                    }

                    &.pagination-previous {
                        a {
                            padding: 0 10px;

                            &::before {
                                margin-right: 0;
                            }
                        }
                    }

                    &.pagination-next {
                        a {
                            padding: 0 10px;

                            &::after {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
