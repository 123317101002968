.wb-tab {
  background: white;
  margin-bottom: 30px;

  @media screen and (max-width: 699px) {
    margin-bottom: 20px;
  }
}

.wb-tab-header {
  background: #dde6ef;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  font-weight: 700;
  font-size: 17px;
  color: #7f8d9a;
  letter-spacing: -0.2px;
  text-align: center;
  padding: 0 20px;

  @media screen and (max-width: 699px) {
    padding: 0;
    overflow: scroll;
  }


  &__tab {
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: darken($gray-font, 20);
    }


    &.selected {
      background: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(127, 141, 154, 0.4), inset 0 2px 0 0 #17a0f8;
      border-radius: 4px 4px 0 0;
      font-weight: 700;
      color: #1d3343;
    }

      span {
        @media screen and (max-width: 699px) {
          padding: 0 20px;
        }
      }

    kit-popover {
      width: 100%;
    }

    ::ng-deep kit-popover {
      .wb-toolbox {
        position: absolute !important;
        left: 75%;
      }
    }

  }
}

.wb-tab__content {
  position: relative;
  z-index: 2;
  top: -2px;
  background: white;
  padding: 0 30px;
  padding-top: 53px;
  padding-bottom: 50px;

  @media screen and (max-width: 699px) {
    padding: 20px;
    padding-bottom: 0;
  }

}

