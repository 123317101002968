$input-btn-padding-y:         .4rem !default;
$input-btn-padding-x:         1.4rem !default;
$btn-border-radius:           1.7rem !default;

$input-btn-padding-y-sm:      .2rem !default;
$input-btn-padding-x-sm:      1.1rem !default;
$input-btn-font-size-sm:      0.85rem !default;
$btn-line-height-sm:          1.6rem !default;
$btn-border-radius-sm:        1.5rem !default;

$input-btn-padding-y-lg:      .8rem !default;
$input-btn-padding-x-lg:      1.6rem !default;
$input-btn-font-size-lg:      1rem !default;
$btn-border-radius-lg:        2rem !default;

$input-btn-border-width:      2px !default;
