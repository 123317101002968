@use 'sass:color';
@import "palettes/material-palette-green";
@import "palettes/material-palette-blue";
@import "palettes/material-palette-pink";
@import "palettes/material-palette-red";

$primary-palette: (
    50: var(--ndl-primary-50),
    100: var(--ndl-primary-100),
    200: var(--ndl-primary-200),
    300: var(--ndl-primary-300),
    400: var(--ndl-primary-400),
    500: var(--ndl-primary-500),
    600: var(--ndl-primary-600),
    700: var(--ndl-primary-700),
    800: var(--ndl-primary-800),
    900: var(--ndl-primary-900),
    A100: var(--ndl-primary-A100),
    A200: var(--ndl-primary-A200),
    A400: var(--ndl-primary-A400),
    A700: var(--ndl-primary-A700),
    contrast: (
        50: var(--ndl-primary-contrast-50),
        100:  var(--ndl-primary-contrast-100),
        200:  var(--ndl-primary-contrast-200),
        300:  var(--ndl-primary-contrast-300),
        400:  var(--ndl-primary-contrast-400),
        500:  var(--ndl-primary-contrast-500),
        600:  var(--ndl-primary-contrast-600),
        700:  var(--ndl-primary-contrast-700),
        800:  var(--ndl-primary-contrast-800),
        900:  var(--ndl-primary-contrast-900),
        A100:  var(--ndl-primary-contrast-A100),
        A200:  var(--ndl-primary-contrast-A200),
        A400:  var(--ndl-primary-contrast-A400),
        A700:  var(--ndl-primary-contrast-A700),
    )
);

$accent-palette: (
    50: var(--ndl-accent-50),
    100: var(--ndl-accent-100),
    200: var(--ndl-accent-200),
    300: var(--ndl-accent-300),
    400: var(--ndl-accent-400),
    500: var(--ndl-accent-500),
    600: var(--ndl-accent-600),
    700: var(--ndl-accent-700),
    800: var(--ndl-accent-800),
    900: var(--ndl-accent-900),
    A100: var(--ndl-accent-A100),
    A200: var(--ndl-accent-A200),
    A400: var(--ndl-accent-A400),
    A700: var(--ndl-accent-A700),
    contrast: (
        50: var(--ndl-accent-contrast-50),
        100:  var(--ndl-accent-contrast-100),
        200:  var(--ndl-accent-contrast-200),
        300:  var(--ndl-accent-contrast-300),
        400:  var(--ndl-accent-contrast-400),
        500:  var(--ndl-accent-contrast-500),
        600:  var(--ndl-accent-contrast-600),
        700:  var(--ndl-accent-contrast-700),
        800:  var(--ndl-accent-contrast-800),
        900:  var(--ndl-accent-contrast-900),
        A100:  var(--ndl-accent-contrast-A100),
        A200:  var(--ndl-accent-contrast-A200),
        A400:  var(--ndl-accent-contrast-A400),
        A700:  var(--ndl-accent-contrast-A700),
    )
);

$warn-palette: (
    50: var(--ndl-warn-50),
    100: var(--ndl-warn-100),
    200: var(--ndl-warn-200),
    300: var(--ndl-warn-300),
    400: var(--ndl-warn-400),
    500: var(--ndl-warn-500),
    600: var(--ndl-warn-600),
    700: var(--ndl-warn-700),
    800: var(--ndl-warn-800),
    900: var(--ndl-warn-900),
    A100: var(--ndl-warn-A100),
    A200: var(--ndl-warn-A200),
    A400: var(--ndl-warn-A400),
    A700: var(--ndl-warn-A700),
    contrast: (
        50: var(--ndl-warn-contrast-50),
        100:  var(--ndl-warn-contrast-100),
        200:  var(--ndl-warn-contrast-200),
        300:  var(--ndl-warn-contrast-300),
        400:  var(--ndl-warn-contrast-400),
        500:  var(--ndl-warn-contrast-500),
        600:  var(--ndl-warn-contrast-600),
        700:  var(--ndl-warn-contrast-700),
        800:  var(--ndl-warn-contrast-800),
        900:  var(--ndl-warn-contrast-900),
        A100:  var(--ndl-warn-contrast-A100),
        A200:  var(--ndl-warn-contrast-A200),
        A400:  var(--ndl-warn-contrast-A400),
        A700:  var(--ndl-warn-contrast-A700),
    )
);

@mixin declareHues($palette, $mainHue: 500, $theme: "primary") {
    @each $hue, $color in $palette  {
        @if $hue != "contrast" {
            @if $hue == $mainHue {
                --ndl-#{$theme}: #{$color};
                --ndl-#{$theme}-hover: #{color.adjust($color, $lightness: -10%)};
                --ndl-#{$theme}-shadow: #{$color+"1E"};
                --ndl-#{$theme}-#{$hue}: var(--ndl-#{$theme});
            } @else {
                --ndl-#{$theme}-#{$hue}: #{$color};
            }
        }
        @else {
            @each $contrastHue, $contrastColor in $color  {
                --ndl-#{$theme}-contrast-#{$contrastHue}: #{$contrastColor};
            }
        }
    }
}
