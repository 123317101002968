@use "sass:math";

/// INITIALIZATION ///
$ndl-base-size: 14;

// Used on html tag
$ndl-base-px: $ndl-base-size * 1px;

@function pxToRem($value) {
    @return math.div($value, $ndl-base-size) * 1rem;
}

/// VALUES ///

// Texts Sizes
$ndl-text-xxs: pxToRem(12);
$ndl-text-xs: pxToRem(14);
$ndl-text-sm: pxToRem(16);
$ndl-text-md: pxToRem(18);
$ndl-text-lg: pxToRem(20);
$ndl-text-xl: pxToRem(22);
$ndl-text-xxl: pxToRem(26);

// Margins Sizes
$ndl-margin-xxs: pxToRem(7);
$ndl-margin-xs: pxToRem(10);
$ndl-margin-sm: pxToRem(14);
$ndl-margin-md: pxToRem(20);
$ndl-margin-lg: pxToRem(24);
$ndl-margin-xl: pxToRem(28);
$ndl-margin-xxl: pxToRem(30);

// Padding Sizes
$ndl-padding-xxs: pxToRem(7);
$ndl-padding-xs: pxToRem(10);
$ndl-padding-sm: pxToRem(14);
$ndl-padding-md: pxToRem(20);
$ndl-padding-lg: pxToRem(20);
$ndl-padding-xl: pxToRem(28);
$ndl-padding-xxl: pxToRem(30);

// Borders
$ndl-border-sm: pxToRem(1);
$ndl-border-md: pxToRem(2);
$ndl-border-lg: pxToRem(4);

// Border Radius
$ndl-radius-xs: pxToRem(4);
$ndl-radius-sm: pxToRem(8);
$ndl-radius-md: pxToRem(10);
$ndl-radius-lg: pxToRem(14);
$ndl-radius-round: 50%;
