@import 'bootstrap-override/wb-variables';
@import "ndl-variables";

.wb-form {
  .wb-label {
    font-size: 14px;
    color: $blue-dark;
    letter-spacing: 0;
    display: block;

    @media screen and (max-width: 699px) {
      font-size: 15px;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: 0.8;
    font-size: 15px;
    color: rgba(29, 51, 67, 0.50);
    letter-spacing: 0;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    opacity: 0.8;
    font-size: 15px;
    color: rgba(29, 51, 67, 0.50);
    letter-spacing: 0;
  }
  :-ms-input-placeholder { /* IE 10+ */
    opacity: 0.8;
    font-size: 15px;
    color: rgba(29, 51, 67, 0.50);
    letter-spacing: 0;
  }
  :-moz-placeholder { /* Firefox 18- */
    opacity: 0.8;
    font-size: 15px;
    color: rgba(29, 51, 67, 0.50);
    letter-spacing: 0;
  }


  .wb-checkbox.wb-checkbox--selected {
    background: var(--ndl-primary);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    border-color: transparent;
    color: white;
  }

  ng4geo-autocomplete .custom-autocomplete {
    display: block;
    margin-bottom: 29px;

    @media screen and (max-width: 699px) {
      margin-bottom: 19px;
    }
  }

  kit-form-phone {
    display: block;
    margin-bottom: 29px;

    @media screen and (max-width: 699px) {
      margin-bottom: 19px;
    }
  }

  kit-form-input, kit-form-input-float {
    display: block;
    margin-bottom: 29px;

    @media screen and (max-width: 699px) {
      margin-bottom: 19px;
    }

    .wb-helper {
      margin-top: 4px;
      font-size: 13px;
      color: $gray-font;
      letter-spacing: -0.19px;
      line-height: 18px;
      font-weight: 400;
    }
  }

  kit-form-address {
    display: block;
    margin-bottom: 29px;

    @media screen and (max-width: 699px) {
      margin-bottom: 19px;
    }
  }

  .wb-input {
    background: $darken-white;
    border: 1px solid $lighter-grey;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    line-height: 50px;
    padding: 0 20px;
    width: 100%;
    min-height: 50px;
    flex-grow: 100;

    &.has-error {
      background: #FDF6F6;
      border: 1px solid $red-error;
    }

    &.has-success {
      background: $darken-white;
      border: 1px solid #36B452;
    }

    &.read-only {
      background: #EBECEF;
    }

  }
  .wb-input.wb-input-tel{
    padding: 0px 0px 0px 0px;
  }

  .wb-error-block {
    background: #FDF6F6;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 13px 40px 17px;
    margin-bottom: 20px;

    &__oops {
      display: block;
      color: $red-error;
    }

    &__description {
      display: block;
      color: $grey;
    }
  }

  .wb-success-block {
    background: #eaf3e8;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 13px 40px 17px;
    margin-bottom: 20px;

    &__title {
      display: block;
      color: var(--ndl-primary);
    }

    &__description {
      display: block;
      color: $blue-dark;
    }
  }


  .wb-label-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .wb-required {
    font-size: 14px;
    color: $red-error;
    letter-spacing: 0;
    position: relative;
    top: -2px;
    left: 2px;
  }

  .wb-error-message {
    font-weight: 400;
    font-size: 13px;
    color: $red-error;
    letter-spacing: 0;
    text-align: right;
  }

  .wb-success-message {
    font-weight: 400;
    font-size: 13px;
    color: #36B452;
    letter-spacing: 0;
    text-align: right;
  }

  .ng-select .ng-select-container {
    background: #FFFFFF;
    border: 1px solid $lighter-grey;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    border-radius: 3px;
  }

  ng-select.ng-select.has-error {
    .ng-select-container {
      background: #FDF6F6;
      border-color: $red-error;
    }
  }

  .ng-select .ng-select-container {
    height: 50px;
    margin-bottom: 30px;

    @media screen and (max-width: 699px) {
      margin-bottom: 20px;
    }
  }

  .ng-select .ng-select-container .ng-value-container {
    padding-left: 20px;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    height: 50px;
    padding-left: 20px;
  }

  .ng-select.ng-multiple .ng-select-container .ng-value-container .ng-input > input {
    padding-left: 0;
  }

  ng-select.filtered .ng-placeholder {
    display: none!important;
  }

  .ng-select.ng-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: inherit;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    text-overflow: ellipsis;
    font-weight: 400;

  }

  .ng-select .ng-select-container .ng-value-container .ng-input {
    padding-left: 0;
    left: 0;
    top: 0;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 8px 20px;
  }


  .ng-select .ng-select-container .ng-value-container .ng-value {
    font-weight: 700;
    font-size: 15px;
    color: $blue-dark;
    letter-spacing: 0;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 0 20px;
    line-height: 50px;
    height: 50px;
  }

  .ng-select .ng-dropdown-footer {
    line-height: 50px;
    padding: 0 20px;
    cursor: pointer;
  }

  .wb-select-footer {
    font-size: 15px;
    color: $blue-dark;
    letter-spacing: 0;

    &:after {
      font-family: 'icon';
      font-size: 20px;
      color: $blue-font-btn;
      content: "\e93a";
      line-height: 50px;
      float: right;

    }
  }


  kit-form-textarea {
    margin-bottom: 35px;
    display: block;

    @media screen and (max-width: 699px) {
      margin-bottom: 29px;
    }

    textarea {
      height: 110px;
      /* Rectangle 10: */
      background: $darken-white;
      border: 1px solid $lighter-grey;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.10);
      border-radius: 3px;
      font-size: 15px;

      width: 100%;
      padding: 20px;

      &.has-error {
        background: #FDF6F6;
        border: 1px solid $red-error;
      }
    }

    .text-area-c-height {
      height: 160px;
    }

  }

  .wb-checkbox {
    width: 100%;
    /* Rectangle 28: */
    background: #FFFFFF;
    border: 2px solid #EBECEF;
    border-radius: 4px;
    line-height: 50px;
    padding-left: 24px;
    padding-right: 40px;
    position: relative;
    cursor: pointer;
    margin-bottom: 12px;

    @media screen and (max-width: 699px) {
      margin-bottom: 10px;
    }


    &--multiline {
      line-height: 30px!important;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 25px;
    }

    img {
      margin-right: 6px;
    }

    .wb-radio {
      background: #FFFFFF;
      border: 1px solid #D7D7D7;
      border-radius: 3px;

      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      display: inline-block;
      height: 20px;
      width: 20px;

      transition: none;
    }

    &.checked {
      background: var(--ndl-primary);
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);
      border-radius: 3px;
      border-color: transparent;
      color: white;

      span.wb-label-helper {
        color: $blue-dark;
      }
    }

    input:checked ~ .wb-radio {
      border-color: var(--ndl-primary);
      background: var(--ndl-primary);

      &:before {
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: calc(90%);
        height: calc(90%);
        background: white;
        border-radius: 3px;
      }
      &:after {
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: calc(50%);
        height: calc(50%);
        background: var(--ndl-primary);
        border-radius: 1px;

      }
    }

    input {
      visibility: hidden;
      position: absolute;
      z-index: 0;
    }
  }
}


.wb-form-media-container {
  background: $darken-white;

  border-bottom: 1px solid #DDE6EF;

  padding: 30px 73px 30px 77px;
  display: flex;
  justify-content:flex-end;
  margin-right: auto;

  margin-bottom: 60px;

  &.has-error {
    background: #FDF6F6!important;
  }

  @media screen and (max-width: 699px) {
    padding: 30px 20px;
    margin-bottom: 30px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &__instruction {


    &.no-media {
      margin-right: auto;
    }
    label {
      /* Company logo (HD): */
      font-size: 15px;
      color: $blue-dark;
      letter-spacing: -0.21px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    p {
      /* Select a file on you: */
      font-weight:300;
      font-size: 13px;
      color: $gray-font;
      letter-spacing: -0.19px;
      line-height: 18px;
      max-width: 171px;
    }
  }

  &__media {
    margin-right: auto;
    margin-left: auto;
    /* Rectangle 10: */
    background: #E5EBF1;
    border: 2px solid #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(29,51,67,0.13);
    border-radius: 3px;
    width: 170px;

    img {
      width: 170px;
    }
  }

  &__button {
    p {
      /* Accepted Format: PNG: */
      font-weight:300;
      font-size: 13px;
      color: $blue-dark;
      letter-spacing: -0.19px;
      line-height: 18px;
    }
  }
}

.wb-form-block {
  display: block;
  background: #FFFFFF;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.05);
  border-radius: 3px;
  padding: 28px 0 68px;

  overflow-x: hidden;
  overflow-y: visible;

  margin-bottom: 100px;

  @media screen and (max-width: 699px) {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 20px;
  }

  &.no-bottom-padding {
    padding-bottom: 0;
  }

  &.no-box-shadow {
    box-shadow: none;
  }

  &.small-margin-bottom {
    margin-bottom: 30px;
  }

  .wb-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    a {
      font-weight: 700;
      font-size: 15px;
      color: $blue-action;
      letter-spacing: -0.21px;
      text-align: right;
      line-height: 24px;
    }
  }

  product-card {
    /* Rectangle 26: */
    border: 2px solid #EBECEF;
    border-radius: 2px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 699px) {
      margin-bottom: 20px;
    }
  }
}


span.wb-counter {
  background: $blue-action;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  align-self: center;
  color: white;
  display: inline-block;
}


.wb-form {
  ng4geo-autocomplete {
    .custom-autocomplete__input input {
      background: $darken-white;
      border: 1px solid $lighter-grey!important;
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.10);
      border-radius: 3px;
      padding-left: 24px!important;
    }

    .search-icon {
      border-left: none!important;
    }

    .search-default-icon {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 49.3 %2851167%29 - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EShape%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Product_Add_Product' transform='translate%28-883.000000, -3517.000000%29' fill='%237F8D9A' stroke='%237F8D9A' stroke-width='0.799999952'%3E%3Cg id='FORM-Copy-7' transform='translate%28120.000000, 3380.000000%29'%3E%3Cg id='INTRO'%3E%3Cg id='Group-5' transform='translate%2870.000000, 121.000000%29'%3E%3Cg id='Group-4'%3E%3Cg id='Group-3'%3E%3Cpath d='M709.857,32.856 C709.671,33.043 709.37,33.043 709.184,32.856 L704.727,28.4 C703.588,29.383 702.123,29.999 700.5,29.999 C696.91,29.999 694,27.09 694,23.499 C694,19.909 696.91,17 700.5,17 C704.09,17 707,19.909 707,23.499 C707,25.122 706.384,26.588 705.4,27.728 L709.856,32.184 C710.044,32.369 710.044,32.671 709.857,32.856 L709.857,32.856 Z M706.001,23.499 C706.001,20.462 703.538,17.999 700.501,17.999 C697.464,17.999 695.001,20.462 695.001,23.499 C695.001,26.537 697.464,29 700.501,29 C703.538,29 706.001,26.537 706.001,23.499 L706.001,23.499 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")!important;
    }

    .search-icon i {
      width: 16px!important;
      height: 16px!important;
      position: relative;
      top: 2px;
    }

    .custom-autocomplete__dropdown {
      background: white;
      box-shadow: 0 2px 7px 0 rgba(29,51,67,0.20)!important;
      border-radius: 3px!important;
      border: none!important;

      li {
        line-height: 50px;
        border-bottom: 1px solid #DDE6EF;

        a {
          padding: 0 24px!important;
        }

        &:last-child {
          border-bottom: none;
        }

        .custom-icon {
          vertical-align: inherit!important;
          top: 2px;
        }
      }
    }

    .custom-autocomplete__dropdown .custom-icon {
      position: relative;
      top: -2px;
    }

    .custom-autocomplete__dropdown .active a {
      background-color: #E7EEF6!important;
      font-weight: 400!important;
      font-size:15px!important;
    }

    .heading {
      display: none!important;
    }

  }

  .wb-instruction {
    /* Description about yo: */
    font-weight: 300;
    font-size: 13px;
    color: $gray-font;
    letter-spacing: -0.19px;
    line-height: 18px;
  }

}


.ng-option.disabled {
  display: none!important;
}

.wb-form-box {
    /* Mask: */
    background: #FFFFFF;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    margin-bottom: 30px;

    padding: 27px 0 10px 40px;

    @include media-breakpoint-down(xs) {
        margin-bottom: 30px;
        padding: 0 20px 10px;
    }

    &.last {
        margin-bottom: 60px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 49px;
        }
    }

    &.no-padding {
        padding: 0;

        .wb-form-box__header {
            padding-top: 28px;
            padding-left: 40px;
            margin-bottom: 0;
            border-bottom: none;

            @include media-breakpoint-down(xs) {
                padding: 19px 20px 18px;
                margin: 0;

                h5 {
                    margin: 0;
                }
            }

        }
    }

    &__header {
        border-bottom: 1px solid #DDE6EF;
        margin-bottom: 49px;

        @include media-breakpoint-down(xs) {
            padding-top: 19px;
            margin-bottom: 19px;
        }

        h5 {
            /* Main information: */
            font-weight: 700;
            font-size: 20px;
            color: $blue-dark;
            letter-spacing: -0.27px;
            line-height: 24px;
            margin-bottom: 28px;

            @include media-breakpoint-down(xs) {
                margin-bottom: 17px;
            }
        }
    }

    &__body {
        padding-left: 30px;
        padding-right: 70px;

        @include media-breakpoint-down(xs) {
            padding: 0;
        }

        .wb-form-selected-category-breadcrumb {
            font-weight: 200;
        }
    }
}
