@use "sass:math";
@use "variables/ndl-sizes" as size;
@use "variables/ndl-colors" as color;

.ndl-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &-item {
        display: flex;
        justify-content: stretch;
        flex-basis: 100%;

        & > * {
            flex: 1 1 auto;
        }

        &:not(&-first) {
            padding-left: math.div(size.$ndl-padding-md, 2);
        }

        &:not(&-last) {
            padding-right: math.div(size.$ndl-padding-md, 2);
        }
    }

    &-prefix, &-suffix {
        flex-basis: 100%
    }
}

.ndl-list-actions {
    display: flex;
    align-items: center;
    border-radius: size.$ndl-radius-md;
    background-color: var(--ndl-primary-shadow);
    margin-bottom: size.$ndl-margin-sm;

    .ndl-list-action {
        &.ndl-button .ndl-button-container:not(:hover) {
            background-color: unset;
            color: var(--ndl-primary);
        }
    }

    .ndl-actions-clear {
        margin-left: auto;
    }
}

/**
 TODO: Check where this is used
 */
.ndl-list {
    .ndl-list-row:not(:last-child) {
        border-bottom: 1px solid #DDE6EF;
    }
}
