@use "variables/ndl-colors" as colors;
@use "variables/ndl-sizes" as size;

$cardMarginBottom: size.$ndl-margin-xxl;
$cardBorderRadius: size.$ndl-radius-md;
$cardPadding: size.$ndl-padding-md;

.ndl-snackbar-overlay {
    &.mat-snack-bar-container {
        background-color: var(--ndl-accent);
        border-radius: $cardBorderRadius;
        color: colors.$ndl-white;
        padding: size.$ndl-padding-lg;
        max-width: unset;

        .mat-simple-snackbar-action {
            color: var(--ndl-primary);
        }
    }
}
