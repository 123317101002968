.wb-router {
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.05);
  border-radius: 2px;
  margin-bottom: 60px;

  li {
    cursor: pointer;
    list-style-type: circle;

    &:hover {
      color: lighten(black, 30);
    }
  }
}
