@import '~sass/bootstrap-override/wb-variables';

@font-face {
  font-family: 'icon';
  src: url($app-url + '/assetsFiles/fonts/icomoon.eot?rf4pc9');
  src: url($app-url + '/assetsFiles/fonts/icomoon.eot?rf4pc9#iefix') format('embedded-opentype'),
  url($app-url + '/assetsFiles/fonts/icomoon.ttf?rf4pc9') format('truetype'),
  url($app-url + '/assetsFiles/fonts/icomoon.woff?rf4pc9') format('woff'),
  url($app-url + '/assetsFiles/fonts/icomoon.svg?rf4pc9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.wb-ico {
  display: inline-block;
  vertical-align: middle;
  font: normal normal normal 14px/1 'icon';
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color .3s ease;
  text-align: center;
  position: relative;
  top: -2px;

  &-co-packer:before {
    content: "\e900";
  }

  &-countries:before {
    content: "\e901";
  }

  &-facility:before {
    content: "\e902";
  }

  &-food-service:before {
    content: "\e903";
  }

  &-globe-targets:before {
    content: "\e904";
  }

  &-globe:before {
    content: "\e905";
  }

  &-handshake-target:before {
    content: "\e906";
  }

  &-handshake:before {
    content: "\e907";
  }

  &-importer:before {
    content: "\e908";
  }

  &-nav-left:before {
    content: "\e909";
  }

  &-nav-right:before {
    content: "\e90a";
  }

  &-priorities:before {
    content: "\e90b";
  }

  &-retailer:before {
    content: "\e90c";
  }

  &-sourcing:before {
    content: "\e90d";
  }

  &-stores:before {
    content: "\e90e";
  }

  &-turnover:before {
    content: "\e90f";
  }

  &-webinars:before {
    content: "\e910";
  }

  &-workforce:before {
    content: "\e911";
  }

  &-globe-big:before {
    content: "\e912";
  }

  &-globe-target-big:before {
    content: "\e913";
  }

  &-handshake-big:before {
    content: "\e914";
  }

  &-handshake-target-big:before {
    content: "\e915";
  }

  &-importers:before {
    content: "\e916";
  }

  &-retailers:before {
    content: "\e917";
    font-size: 30px;
  }

  &-co-packer2:before {
    content: "\e918";
  }

  &-food-service2:before {
    content: "\e919";
  }

  &-shape:before {
    content: "\e91a";
  }

  &-attachment:before {
    content: "\e91b";
  }

  &-breadcrumb:before {
    content: "\e91c";
  }

  &-burger:before {
    content: "\e91d";
  }

  &-call:before {
    content: "\e91e";
  }

  &-category:before {
    content: "\e91f";
  }

  &-check:before {
    content: "\e920";
  }

  &-client:before {
    content: "\e921";
  }

  &-clock:before {
    content: "\e922";
  }

  &-close:before {
    content: "\e923";
  }

  &-dropdown:before {
    content: "\e924";
  }

  &-email-verified:before {
    content: "\e925";
  }

  &-expand:before {
    content: "\e926";
  }

  &-external:before {
    content: "\e927";
  }

  &-feature:before {
    content: "\e928";
  }

  &-footer-linkedin:before {
    content: "\e929";
  }

  &-footer-twitter:before {
    content: "\e92a";
  }

  &-footer-youtube:before {
    content: "\e92b";
  }

  &-globe-target:before {
    content: "\e92c";
  }

  &-globe:before {
    content: "\e92d";
  }

  &-handshake-target:before {
    content: "\e92e";
  }

  &-handshake:before {
    content: "\e92f";
  }

  &-heart:before {
    content: "\e930";
  }

  &-help:before {
    content: "\e931";
  }

  &-linkedin:before {
    content: "\e932";
  }

  &-lock-big:before {
    content: "\e933";
  }

  &-lock-small:before {
    content: "\e934";
  }

  &-message:before {
    content: "\e935";
  }

  &-minimize:before {
    content: "\e936";
  }

  &-more:before {
    content: "\e937";
  }

  &-nav-left:before {
    content: "\e938";
  }

  &-nav-right:before {
    content: "\e939";
  }

  &-next:before {
    content: "\e93a";
  }

  &-print:before {
    content: "\e93b";
  }

  &-priorities:before {
    content: "\e93c";
  }

  &-search:before {
    content: "\e93d";
  }

  &-star-light:before {
    content: "\e93e";
  }

  &-suscribe:before {
    content: "\e93f";
  }

  &-suscribed:before {
    content: "\e940";
  }

  &-target:before {
    content: "\e941";
  }

  &-unlock-small:before {
    content: "\e942";
  }

  &-unzoom:before {
    content: "\e943";
  }

  &-upload:before {
    content: "\e944";
  }

  &-verified:before {
    content: "\e945";
  }

  &-zoom:before {
    content: "\e946";
  }

  &-sample:before {
    content: "\e947";
  }

  &-mosaic:before {
    content: "\e948";
  }

  &-certifications:before {
    content: "\e949";
  }


  &-co-packer,
  &-facility,
  &-food-service,
  &-globe-targets,
  &-globe,
  &-handshake-target-big,
  &-handshake-target,
  &-handshake,
  &-handshake-big,
  &-importer,
  &-nav-left,
  &-nav-right,
  &-priorities,
  &-countries,
  &-nav-left,
  &-nav-right,
  &-priorities,
  &-sourcing,
  &-stores,
  &-turnover,
  &-webinars,
  &-workforce,
  &-globe-big,
  &-globe-target-big,
  &-retailer {
    font-size: 32px;
  }

}

i.wb-ico-round {
    height: 22px;
    width: 22px;
    padding-top: 4px;
    border-radius: 15px;
}
i.wb-ico-yellow {
    background-color: #F7A45E;
    color: white;
}
i.wb-ico-green {
    background-color: #36B452;
    color: white;
}
