@use "variables/ndl-sizes" as sizes;
@use "variables/ndl-colors" as colors;
@use "variables/ndl-typography" as typography;
@use "variables/ndl-shadows" as shadows;
@use "ndl-theming" as theming;

// Typography
.ndl-small {
    @include typography.ndl-small;
}
.ndl-h1 {
    @include typography.ndl-h1;
}
.ndl-h2 {
    @include typography.ndl-h2;
}
.ndl-h3 {
    @include typography.ndl-h3;
}
.ndl-h4 {
    @include typography.ndl-h4;
}
.ndl-h5 {
    @include typography.ndl-h5;
}
.ndl-h6 {
    @include typography.ndl-h6;
}

.ndl-primary {
    @include theming.ndl-primary;
}
.ndl-secondary {
    @include theming.ndl-secondary;
}
.ndl-info {
    @include theming.ndl-info;
}
.ndl-success {
    @include theming.ndl-success;
}
.ndl-warning {
    @include theming.ndl-warning;
}
.ndl-danger {
    @include theming.ndl-danger;
}
.ndl-neutral {
    @include theming.ndl-neutral;
}

.ndl-primary-text {
    @include theming.ndl-primary-text;
}
.ndl-secondary-text {
    @include theming.ndl-secondary-text;
}
.ndl-info-text {
    @include theming.ndl-info-text;
}
.ndl-success-text {
    @include theming.ndl-success-text;
}
.ndl-warning-text {
    @include theming.ndl-warning-text;
}
.ndl-danger-text {
    @include theming.ndl-danger-text;
}
.ndl-neutral-text {
    @include theming.ndl-neutral-text;
}
.ndl-dark-text {
    @include theming.ndl-dark-text;
}

.ndl-primary-border {
    @include theming.ndl-primary-text;
    border-style: solid;
    border-width: sizes.$ndl-border-sm;
}
.ndl-secondary-border {
    @include theming.ndl-secondary-text;
    border-style: solid;
    border-width: sizes.$ndl-border-sm;
}
.ndl-info-border {
    @include theming.ndl-info-text;
    border-style: solid;
    border-width: sizes.$ndl-border-sm;
}
.ndl-success-border {
    @include theming.ndl-success-text;
    border-style: solid;
    border-width: sizes.$ndl-border-sm;
}
.ndl-warning-border {
    @include theming.ndl-warning-text;
    border-style: solid;
    border-width: sizes.$ndl-border-sm;
}
.ndl-danger-border {
    @include theming.ndl-danger-text;
    border-style: solid;
    border-width: sizes.$ndl-border-sm;
}
.ndl-neutral-border {
    @include theming.ndl-neutral-text;
    border-style: solid;
    border-width: sizes.$ndl-border-sm;
}

// Elevation
.ndl-elevation-z1 {
    box-shadow: shadows.$ndl-box-shadow-1;
}
.ndl-elevation-z2 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05);
}

.ndl-profile-picture {
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    height: sizes.pxToRem(80);
    width: sizes.pxToRem(80);
    overflow: hidden;
    background-color: colors.$ndl-grey;
    display: flex;

    .mat-icon {
        color: colors.$ndl-dark-grey;
        height: sizes.pxToRem(50);
        width: sizes.pxToRem(50);
        border-radius: 50%;
        font-size: sizes.pxToRem(50);
        margin: auto;
    }

    .mat-icon.oversize {
        color: colors.$ndl-dark-grey;
        height: sizes.pxToRem(80);
        width: sizes.pxToRem(80);
        border-radius: 50%;
        font-size: sizes.pxToRem(80);
        transform: scale(1.2) translateY(10%);
    }
}

@mixin scrollbarTheming() {
    &::-webkit-scrollbar {
        height: sizes.$ndl-margin-sm;
        width: sizes.$ndl-margin-sm;
    }
    &::-webkit-scrollbar-thumb {
        background-color: colors.$ndl-grey;
        border-radius: sizes.$ndl-radius-lg;
        border: sizes.$ndl-border-md solid colors.$ndl-extra-light-grey;

        &:hover {
            background-color: colors.$ndl-dark-grey;
        }
    }
    &::-webkit-scrollbar-track-piece {
    }
    &::-webkit-scrollbar-track {
        background-color: colors.$ndl-extra-light-grey;
        border-radius: sizes.$ndl-radius-lg;
    }
}

.ndl-scrollbar {
    @include scrollbarTheming
}

.ndl-text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ndl-icon-bullet {
    &.mat-icon {
        width: sizes.pxToRem(22)!important;
        height: sizes.pxToRem(22)!important;
        border-radius: sizes.$ndl-radius-round;
        display: flex;
        align-items: center;
        justify-content: center;
        @include theming.ndl-neutral-text();
        border: sizes.$ndl-border-sm solid colors.$ndl-grey;
    }
}
