@use '~@angular/material/core/style/variables';
@use '~@angular/material/core/style/private';
@use '~@angular/material/stepper/stepper-variables';

.ndl-stepper {
    &.mat-stepper-vertical,
    &.mat-stepper-horizontal,
    .mat-drawer {
        background-color: inherit;
    }

    .mat-horizontal-stepper-header-container {
        white-space: nowrap;
        display: flex;
        align-items: center;

        .mat-stepper-label-position-bottom & {
            align-items: flex-start;
        }
    }

    .mat-stepper-horizontal-line {
        border-top-width: 3px;
        border-top-style: solid;
        flex: auto;
        height: 0;
        margin: 0 -38px;
        min-width: stepper-variables.$line-gap + stepper-variables.$side-gap;

        .mat-stepper-label-position-bottom & {
            margin: 0;
            min-width: 0;
            position: relative;
        }
    }

    %mat-header-horizontal-line-label-position-bottom {
        $half-side-gap: private.private-div(stepper-variables.$side-gap, 2);
        border-top-width: stepper-variables.$line-width;
        border-top-style: solid;
        content: '';
        display: inline-block;
        height: 0;
        position: absolute;
        width: calc(50% - #{$half-side-gap + stepper-variables.$line-gap});
    }

    .mat-horizontal-stepper-header {
        display: flex;
        height: stepper-variables.$header-height;
        overflow: hidden;
        align-items: center;
        padding: 0 stepper-variables.$side-gap;

        .mat-step-icon {
            margin-right: stepper-variables.$line-gap;
            flex: none;

            [dir='rtl'] & {
                margin-right: 0;
                margin-left: stepper-variables.$line-gap;
            }
        }

        .mat-stepper-label-position-bottom & {
            box-sizing: border-box;
            flex-direction: column;
            // We use auto instead of fixed 104px (by spec) because when there is an optional step
            //  the height is greater than that
            height: auto;

            &:not(:last-child)::after,
            [dir='rtl'] &:not(:first-child)::after {
                @extend %mat-header-horizontal-line-label-position-bottom;
                right: 0;
            }

            &:not(:first-child)::before,
            [dir='rtl'] &:not(:last-child)::before {
                @extend %mat-header-horizontal-line-label-position-bottom;
                left: 0;
            }

            [dir='rtl'] &:last-child::before,
            [dir='rtl'] &:first-child::after {
                display: none;
            }

            & .mat-step-icon {
                // Cleans margin both for ltr and rtl direction
                margin-right: 0;
                margin-left: 0;
            }

            & .mat-step-label {
                padding: stepper-variables.$label-position-bottom-top-gap 0 0 0;
                text-align: center;
                width: 100%;
            }
        }
    }

    .mat-vertical-stepper-header {
        display: flex;
        align-items: center;

        // We can't use `max-height` here, because it breaks the flexbox centering in IE.
        height: stepper-variables.$label-header-height;

        .mat-step-icon {
            margin-right: stepper-variables.$vertical-stepper-content-margin - stepper-variables.$side-gap;

            [dir='rtl'] & {
                margin-right: 0;
                margin-left: stepper-variables.$vertical-stepper-content-margin - stepper-variables.$side-gap;
            }
        }
    }

    .mat-horizontal-stepper-content {
        outline: 0;

        &[aria-expanded='false'] {
            height: 0;
            overflow: hidden;
        }
    }
    //TODO: CHECK WITH MIKE MONDAY
    .mat-horizontal-content-container {
        //overflow: hidden;
        padding: 0 stepper-variables.$side-gap stepper-variables.$side-gap stepper-variables.$side-gap;
    }

    .mat-vertical-content-container {
        margin-left: stepper-variables.$vertical-stepper-content-margin;
        border: 0;
        position: relative;

        [dir='rtl'] & {
            margin-left: 0;
            margin-right: stepper-variables.$vertical-stepper-content-margin;
        }
    }

    .mat-stepper-vertical-line::before {
        content: '';
        position: absolute;
        left: 0;
        border-left-width: stepper-variables.$line-width;
        border-left-style: solid;

        [dir='rtl'] & {
            left: auto;
            right: 0;
        }
    }

    .mat-vertical-stepper-content {
        overflow: hidden;
        outline: 0;
    }

    .mat-vertical-content {
        padding: 0;
    }

    .mat-step:last-child {
        .mat-vertical-content-container {
            border: none;
        }
    }
}
