@import '~sass/bootstrap-override/wb-variables';

html, body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

body {
  font-family: "Nunito", Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.wb-container {
  max-width: 1440px;
  padding: 0 12px;
  margin: auto;
}


.wb-box-container {
  width: calc(100% - 80px);
  max-width: 1400px;
  margin: auto;
  display: block;

  @media screen and (max-width: 699px) {
    width: 100%;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}


.wb-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(29, 51, 67, 0.60);
  z-index: 5;
}

.wb-popin {
  z-index: 8;
}

.weak {
  font-weight: 300;
}


.small-gutter {
  margin-right: -10px;
  margin-left: -10px;

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding: 0 10px;
  }
}


a:hover {
  text-decoration: none;
}


.popin-open {
  overflow: hidden;
  //position: fixed;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;

}


.ng-select .ng-arrow-zone {
  margin-right: 8px;
}

.wb-view-all {
  /* View all suppliers: */
  font-size: 15px;
  color: rgba(29, 51, 67, 0.50);
  letter-spacing: -0.39px;
  text-align: center;
  line-height: 50px;

  a {
    color: rgba(29, 51, 67, 0.50);

    &:hover {
      color: rgba(29, 51, 67, 0.70);
    }
  }
}


.amcharts-export-menu .export-main:hover > a, .amcharts-export-menu .export-main.active > a, .amcharts-export-menu .export-main > a, .amcharts-export-menu .export-drawing > a, .amcharts-export-menu .export-delayed-capturing > a {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='20px' viewBox='0 0 16 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 50.2 %2855047%29 - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Ewb-print%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='wb-print' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M9.33333333,15.3333333 L5.33333333,15.3333333 L5.33333333,14.6666667 L9.33333333,14.6666667 L9.33333333,15.3333333 Z M16,5.33333333 L16,14 L13.3333333,14 L13.3333333,18 L2.66666667,18 L2.66666667,14 L0,14 L0,5.33333333 L2.66666667,5.33333333 L2.66666667,2 L13.3333333,2 L13.3333333,5.33333333 L16,5.33333333 Z M12,12 L4,12 L4,16.6666667 L12,16.6666667 L12,12 Z M12,3.33333333 L4,3.33333333 L4,5.33333333 L12,5.33333333 L12,3.33333333 Z M14.6666667,7 C14.6666667,6.816 14.5173333,6.66666667 14.3333333,6.66666667 C14.1493333,6.66666667 14,6.816 14,7 C14,7.184 14.1493333,7.33333333 14.3333333,7.33333333 C14.5173333,7.33333333 14.6666667,7.184 14.6666667,7 Z M10.6666667,13.3333333 L5.33333333,13.3333333 L5.33333333,14 L10.6666667,14 L10.6666667,13.3333333 Z' id='Shape' fill='%23000000' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E") !important;
}

.mt-30 {
  margin-top: 30px;
}

// Google places results
.pac-container {
  .pac-item {
    padding: 10px 20px;
    cursor: pointer;

    span {
      font-family: "Nunito", Arial, sans-serif;
      font-size: 15px;
      color: #353535;
      font-weight: 200;
    }

    .pac-icon-marker {
      background-image: url($app-url + '/assetsFiles/pin.png');
      background-size: 18px;
      margin-right: 10px;
      margin-top: 5px;
    }

    .pac-matched {
      font-weight: 600;
    }
  }
}

.pac-logo::after { // Powered by Google
  margin: 10px;
}
