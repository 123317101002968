@import "variables/ndl-inputs";
@import "variables/ndl-colors";
@import "variables/ndl-sizes";

.ndl-menu {
    width: 100%;

    ul {
        .ndl-menu-item {
            display: block;
            &:not(:last-child) {
                margin-bottom: $ndl-margin-xs;
            }

            li {
                width: 100%;

                a {
                    display: flex;
                    align-items: center;
                    padding: $inputPaddingY $inputPaddingX;
                    text-decoration: none;
                    width: 100%;
                    border-radius: $inputBorderRadius;
                    color: inherit;
                    font-weight: 500;

                    .ndl-small {
                        font-weight: 400;
                    }

                    &.ndl-menu-item-active {
                        color: var(--ndl-primary);
                    }

                    &.ndl-menu-item-with-children {
                        margin-bottom: $ndl-margin-xs;
                    }

                    &.ndl-menu-item-active, &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                    }

                    .submenu-dropdown {
                        margin-left: auto;
                    }
                }
            }

            &.ndl-menu-item-banner {
                width: calc(100% + #{$inputPaddingX} * 2);
                margin-left: -$inputPaddingX;
                margin-right: -$inputPaddingX;

                li {
                    a {
                        flex-direction: column;
                        justify-content: center;
                        color: $ndl-white;
                        border-radius: 0;

                        &:hover {
                            text-decoration: none;
                            color: $ndl-white;
                        }
                    }
                }
            }
        }
    }
}

.ndl-submenu-item {
    display: block;
    &:not(:last-child) {
        margin-bottom: $ndl-margin-xs;
    }
    li {
        a {
            padding: 0 ($inputPaddingX*2) 0 ($inputPaddingX*3) !important;
            color: $ndl-black !important;

            &:hover {
                background-color: transparent !important;
            }

            &.ndl-submenu-item-active {
                color: var(--ndl-primary) !important;
            }
        }
    }
}
