@import "../../variables/ndl-layout";
@import "../../variables/ndl-colors";

.ndl-page, .ndl-stepper {
    .mat-drawer-container {
        background-color: unset;
        flex-grow: 1;

        .mat-drawer-content {
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
        }
    }
}
.ndl-stepper {
    .mat-drawer-side.mat-drawer-start, .mat-drawer-side.mat-drawer-end {
        border: none;
        padding: 0 $content-padding;
    }
}
