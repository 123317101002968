@use "~@angular/material" as mat;
@use "ndl-theming" as theme;
@import "material/material-palettes";

$ndl-primary: mat.get-color-from-palette(theme.$ndl-primary-palette, theme.$ndl-primary-hue);
$ndl-secondary: mat.get-color-from-palette(theme.$ndl-accent-palette, theme.$ndl-accent-hue);
$ndl-warn: mat.get-color-from-palette(theme.$ndl-warn-palette, theme.$ndl-accent-hue);

:root {
    // Material declaration
    @include declareHues(theme.$ndl-primary-palette, theme.$ndl-primary-hue, "primary");
    @include declareHues(theme.$ndl-accent-palette, theme.$ndl-accent-hue, "accent");
    @include declareHues(theme.$ndl-warn-palette, theme.$ndl-warn-hue, "warn");
    --ndl-success: #{theme.$ndl-success};
    --ndl-danger: #{theme.$ndl-danger};
}
