@use "../../variables/ndl-sizes" as sizes;
@use "../../variables/ndl-colors" as colors;
@use "../../ndl-helpers" as helpers;
@import "../../variables/ndl-inputs";

.ndl-form-field {
    padding-bottom: sizes.$ndl-margin-sm;

    .mat-form-field {
        width: 100%;
    }

    .mat-form-field.mat-form-field-appearance-fill {

        .mat-form-field-flex {
            border: 1px solid colors.$ndl-grey;
            background-color: colors.$ndl-extra-light-grey;
            box-shadow: 0 0 1px transparent;
            border-radius: $inputBorderRadius;
            padding: 0;
            align-items: center;
        }

        &:not(.mat-form-field-invalid) {
            .mat-form-field-label {
                color: var(--ndl-accent);
            }
        }

        &.mat-focused:not(.mat-form-field-invalid) {

            .mat-form-field-flex {
                border-color: var(--ndl-accent);
            }
        }

        &.mat-form-field-invalid {
            .mat-form-field-flex {
                border-color: var(--ndl-warn);
            }

            .mat-form-field-prefix {
                .mat-icon {
                    color: var(--ndl-warn);
                }
            }
        }

        .mat-form-field-infix {
            padding: $inputPaddingY 0;
            border-top-width: 0;
            position: unset;
        }

        .mat-form-field-label {
            top: 0;
            margin-top: 0;
            position: relative;
            margin-bottom: $captionMarginBottom;

            .mat-placeholder-required {
                color: var(--ndl-warn);
            }
        }

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        &.mat-form-field-has-label .mat-form-field-wrapper {
            margin-top: $inputWrapperMarginTop;
        }

        .mat-form-field-label-wrapper {
            bottom: 100%;
            padding: 0;
            top: unset;
            width: unset;
            height: unset;
            font-weight: $captionFontWeight;
        }

        &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
        &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
            transform: unset;
            width: unset;
        }

        .mat-form-field-underline {
            display: none;
        }

        .mat-form-field-prefix {
            .mat-icon {
                color: var(--ndl-primary);
            }
        }

        .mat-form-field-prefix, .mat-form-field-suffix {
            display: flex;
            align-items: center;
        }

        .mat-form-field-prefix {
            padding-left: $inputPaddingX;

            & > *:last-child {
                margin-right: $inputPaddingX;
            }
        }

        .mat-form-field-suffix {
            padding-right: $inputPaddingX;

            & > *:first-child {
                margin-left: $inputPaddingX;
            }
        }

        .mat-form-field-prefix, .mat-form-field-suffix {
            height: $inputButtonsSize;
            display: flex;

            .mat-icon-button, .mat-icon {
                height: $inputButtonsSize;
                width: $inputButtonsSize;
                font-size: $inputButtonsSize;
                line-height: $inputButtonsSize;
            }
        }
    }

    .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
        padding: 0;
        position: relative;
    }

    .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
        transform: none;
    }

    .mat-form-field.mat-primary {
        .mat-select-arrow, &.mat-focused .mat-select-arrow {
            color: var(--ndl-primary);
        }
    }
}

.ndl-select-panel-class {
    margin-top: 30px !important;
}

.ndl-radio, .ndl-checkboxes {
    .ndl-form-field {
        .mat-form-field.mat-form-field-appearance-fill {
            &, &.mat-focused {
                .mat-form-field-flex {
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        .ndl-checkboxes-field-content {
            overflow: auto;
            @media screen and (max-width: 999px) {
                display: flex;
                flex-direction: column;
                .mat-checkbox {
                    margin-bottom: sizes.$ndl-margin-xs;
                }
            }
            &.display-mode {
                .mat-checkbox-inner-container {
                    display: none;
                }

                .mat-checkbox-label {
                    color: #000000;
                }
            }
        }
    }

    &:not(.ndl-checkboxes-vertical) {
        .ndl-form-field {
            .ndl-checkboxes-field-content {
                display: flex;
            }
        }
    }

    .ndl-checkboxes-search {
        .mat-form-field.mat-form-field-appearance-fill {
            &, &.mat-focused {
                .mat-form-field-flex {
                    background-color: colors.$ndl-extra-light-grey;
                }
            }
        }
    }
}

.ndl-upload {
    &.ndl-upload-drag-hover {
        .ndl-form-field .mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
            border-color: var(--ndl-primary);

            .ndl-upload-field {
                opacity: .5;
            }
        }
    }

    .ndl-form-field {
        .ndl-upload-field {
            width: 100%;
        }

        .mat-form-field.mat-form-field-appearance-fill {
            &.mat-form-field-invalid .mat-form-field-flex {
                border-color: var(--ndl-warn);
            }

            &, &.mat-focused {
                .mat-form-field-flex {
                    background-color: transparent;
                    border: 1px dashed colors.$ndl-grey;
                    border-radius: $inputUploadBorderRadius;
                }
            }

            .mat-form-field-prefix {
                height: $inputUploadPreviewSize;

                .ndl-upload-field-preview {
                    width: $inputUploadPreviewSize;
                    height: $inputUploadPreviewSize;

                    .mat-icon {
                        font-size: $inputUploadPreviewSize;
                        width: inherit;
                        height: inherit;
                    }
                }
            }
        }
    }

    .ndl-upload-field-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;

        .ndl-upload-field-placeholder-prefix {
            margin-right: sizes.$ndl-margin-xxs;

            & > .mat-icon {
                font-size: 50px;
                height: 50px;
                width: 50px;
            }
        }
    }

    .ndl-upload-field-profile .ndl-upload-field-placeholder {
        justify-content: start;
    }
}

.ndl-upload-file {
    display: flex;
    max-width: 100%;
    flex: 1;

    &.ndl-upload-file-error {
        color: var(--ndl-danger);
    }

    .ndl-upload-file-info {
        margin: auto;
        overflow: hidden;
        flex: 1;

        .ndl-upload-file-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .ndl-upload-file-info-extra {
            color: colors.$ndl-dark-grey;
        }

        .mat-progress-bar {
            height: 0.7143rem;
            border-radius: sizes.$ndl-radius-lg;
        }

        @media screen and (max-width: 999px) {
            max-width: 160px;
        }
    }

    .ndl-upload-file-image {
        background-size: cover;
        background-position: center;
        height: sizes.pxToRem(80);
        width: sizes.pxToRem(80);
        overflow: hidden;
        margin-right: $inputPaddingX;
    }

    .ndl-upload-file-action {
        margin: auto;
    }
}

.ndl-radio, .ndl-checkboxes {
    ndl-radio-field, ndl-checkboxes-field {
        .mat-radio-label, .mat-checkbox-layout {
            margin-bottom: 0;
        }

        .mat-radio-group, .mat-checkbox-group {
            display: flex;
        }

        .mat-radio-button, .mat-checkbox {
            margin-right: sizes.$ndl-margin-xl;


            &.mat-primary {
                &.mat-radio-checked {
                    .mat-radio-outer-circle {
                        border-color: var(--ndl-primary);
                    }
                }

                .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-inner-circle, .mat-radio-persistent-ripple, &:active .mat-radio-persistent-ripple {
                    background-color: var(--ndl-primary);
                }
            }
        }
    }

    &.ndl-radio-vertical, &.ndl-checkboxes-vertical {
        .mat-radio-group, .mat-checkbox-group {
            flex-direction: column;
        }

        .mat-radio-button, .mat-checkbox {
            margin-right: 0;
            margin-bottom: sizes.$ndl-margin-xxs;
        }
    }
}

.ndl-checkboxes {
    .ndl-checkboxes-search {
        .mat-form-field-wrapper {
            margin-top: 0 !important;
        }
    }

    .ndl-checkboxes-group {
        > ul {
            display: none;
            padding-left: sizes.$ndl-padding-xxl;
        }

        .ndl-checkboxes-group-label button.mat-icon-button {
            width: 24px;
            height: 24px;
            line-height: 24px;
            transition: transform 0.3s ease-out;
        }

        &.ndl-checkboxes-group-opened {
            > ul {
                display: block;
            }

            > .ndl-checkboxes-group-label button.mat-icon-button {
                transform: rotate(180deg);
            }
        }

        .ndl-checkboxes-group-count {
            display: inline-block;
            background-color: var(--ndl-primary);
            padding: 0.3rem;
            margin: 0 sizes.$ndl-margin-xxs;
            color: colors.$ndl-white;
            font-weight: bold;
            line-height: 0.7;
            border-radius: sizes.$ndl-radius-xs;
        }
    }

    .ndl-checkbox-hide {
        display: none;
    }

    .ndl-checkbox-error {
        .mat-checkbox-frame {
            border-color: var(--ndl-danger)
        }
    }

    .mat-form-field-infix {
        padding-bottom: 0 !important;
    }
}

.mat-input-element {
    caret-color: var(--ndl-primary) !important;
}

.ndl-wysiwyg .ndl-form-field .mat-form-field.mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0;

    .ck-content {
        border: none;
        border-radius: 0 0 $inputBorderRadius $inputBorderRadius;

        &.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
            box-shadow: none;
        }

        //fix style for bulleted list in ckeditor
        ul li {
            list-style-type: disc;
            list-style-position: inside;
        }
    }

    .ck-toolbar {
        border: none;
        border-radius: $inputBorderRadius $inputBorderRadius 0 0;
        background-color: colors.$ndl-extra-light-grey;
    }
}

.ndl-tags {
    .mat-chip.mat-standard-chip {
        &, &.mat-chip-selected.mat-primary {
            background-color: colors.$ndl-extra-light-grey;
            border: sizes.$ndl-border-sm solid colors.$ndl-grey;
        }
    }

    input.mat-chip-input {
        flex-basis: 100%;
    }
}

.ndl-tag-autocomplete {
    display: flex;
    flex-wrap: wrap;

    .mat-option {
        flex: 1 0 50%;
    }

    @include helpers.scrollbarTheming();
}

.ndl-date {
    .mat-datepicker-toggle {
        display: flex;
    }

    .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
        height: 24px;
        width: 24px;
        color: var(--ndl-primary);
    }
}

.ndl-disabled {
    opacity: 0.7;
}
