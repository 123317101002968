@use "variables/ndl-colors" as colors;
@use "variables/ndl-sizes" as size;
@use "variables/ndl-shadows" as shadows;
@use "ndl-theming" as theming;


$cardMarginBottom: size.$ndl-margin-xxl;
$cardBorderRadius: size.$ndl-radius-md;
$cardPadding: size.$ndl-padding-md;

.ndl-card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: colors.$ndl-white;
    border-radius: $cardBorderRadius;
    margin-bottom: $cardMarginBottom;

    &.ndl-card-clickable:hover {
        cursor: pointer;
    }

    .ndl-card-status-outside {
        position: absolute;
        top: 4px;
        right: 25px;
        transform: translateY(-100%);
        z-index: -1;
    }

    &:not(.ndl-card-no-shadow) {
        box-shadow: shadows.$ndl-box-shadow-2;
    }

    &:not(.ndl-card-no-overflow-hidden) {
        overflow: hidden;
    }

    .ndl-card-content {
        flex: 1;
        padding: $cardPadding;

        & > * {
            flex: 1 1 auto;
        }
    }

    .ndl-card-footer {
        display: block;
        padding: $cardPadding;
    }

    &:not(.ndl-card-default):not(.ndl-card-outlined) {
        .ndl-card-footer-divider {
            border-top-color: rgba(255, 255, 255, 0.25);
        }
    }

    .ndl-card-menu {
        float: right;
    }

    .ndl-card-flag {
        z-index: 1;
        display: block;
        position: absolute;
        top: 38px;
        left: -85px;
        width: 300px;
        text-align: center;
        transform: rotate(-45deg);
        padding: size.$ndl-padding-xxs;
        text-transform: uppercase;
    }

    @include theming.ndl-block-theme("card", false, false, false, true);
}
