// Overriding bootstrap
@import '~sass/bootstrap-override/wb-variables';
@import '~sass/bootstrap-override/wb-mixins';

//// NEEDL IMPORT ////
// These import from the Needl Library should include all Needl Library SASS files
// In order to finish the migration, we should change its use of the CSS classes because all .wb-* classes are now defined as .ndl-*
@import "ndl";

// SCSS Global Definitions
@import '~sass/wb-global';
@import '~sass/wb-form';
@import '~sass/wb-router';
@import '~sass/wb-spinner';
@import '~sass/wb-tab';
@import '~sass/wb-paginator';
@import '~sass/wb-u-text-decoration';

// Fonticons
@import '~sass/base/fonticons';

// Font aliasing
@import "~sass/font-aliasing";

// Other Library imports
@import "~@ng-select/ng-select/themes/default.theme.css";


// Global CSS
.wb-yellow-icon.material-icons {
    color: #F5A934;
    font-size: large;
}

.wb-btn--tag {
    background: #f7f8fa !important;
    border: #f7f8fa !important;
    opacity: 1 !important;
}

.material-icons.wb-ico-category {
    color: $green-confirmation;
}
.material-icons.wb-ico-tag {
    color: $yellow-cta;
}
.material-icons.wb-ico-country {
    color: $ndl-blue;
}
