@import '../base/env';
$blue-action: #17A0F8;
$blue-dark: #1D3343;
$blue-font-btn: #1FA3F8;

$yellow-cta: #F5A934;
$yellow-btn: #FFD60F;

$green-confirmation: #15B639;
$green-info: #94d23d;
$red-info: #bf2634;
$red-error: #E82934;

$gray-font: #7F8D9A;
$darken-white: #F7F8FA;
$lighter-grey: #D8DDE7;

$darkGrey: #4D4D4D;
$grey: #9c9c9c;
$lightGrey: #F2F2F2;
$extraLightGrey: #F8F8F8;

$red: #C03337;
$darkRed: #78181D;
$blue: #1FAEE4;
$darkBlue: #217ABF;

// Category Colors
$savoryGroceryCategoryColor: #c47c13;
$sweetGroceryCategoryColor: #ab2a6e;
$drinksCategoryColor: #775081;
$frozenCategoryColor: #4fabe1;
$dairyCategoryColor: #ea663c;
$chilledCategoryColor: #69a522;
$householdCategoryColor: #797979;
$personalCareCategoryColor: #45beb7;
$bazarCategoryColor: #796756;
$wineSpiritCategoryColor: #911d2e;
$dairyFreeCategoryColor: #e7c600;
$meatFreeCategoryColor: #00513a;

//Event Colors
$frozenEventColor: #6498bb;
$chilledEventColor: #bbd4a9;
$homeEventColor: #8d78a4;
$beautyEventColor: #e94665;
$sweetEventColor: #fc9bb7;
$savouryEventColor: #555258;
$drinksEventColor: #f5cd00;
$winespiEventColor: #9b0026;

//Motion variables
$m-controls: 0.1s linear;
$m-small: 0.2s ease-in-out;
$m-big: 0.3s ease-in-out;

// Main colors Needl.
$needlPink: #E86368;

// Breakpoints
$container-max-widths: (
    sm: 720px,
    md: 1100px,
    lg: 1200px,
    xl: 1280px
);

$grid-breakpoints: (
    xs: 0,
    sm: 700px,
    md: 1000px,
    lg: 1200px,
    xl: 1440px
);
