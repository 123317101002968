@use "variables/ndl-sizes" as sizes;
@use "ndl-helpers" as helper;

.ndl-modal-overlay {
    width: 100%;
    height: 100%;

    * {
        @include helper.scrollbarTheming
    }

    .mat-dialog-container {
        position: relative;
        margin: auto;
        overscroll-behavior-y: auto;
        max-height: 85%;
        max-width: 85%;
        padding: sizes.$ndl-padding-lg;
        box-sizing: content-box;
        width: unset;
        height: unset;
        border-radius: sizes.$ndl-radius-md;
    }

    .mat-dialog-content {
        flex: 1;
        max-height: unset;
        overflow: unset;

        @media screen and (min-width: 768px) {
            margin: unset;
        }
    }

    .ndl-modal-template {
        overflow: hidden;
        display: flex;
        max-height: 100%;

        &-close {
            position: absolute;
            right: 0;
        }

        .ndl-modal-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            overflow: hidden;
            width: sizes.pxToRem(150);
            height: sizes.pxToRem(150);
        }

        .ndl-modal-icon, .ndl-modal-icon-mobile {
            .mat-icon {
                font-size: sizes.pxToRem(200);
                right: sizes.pxToRem(-50);
                bottom: sizes.pxToRem(-50);
                opacity: 0.2;
                z-index: 0;
                color: var(--ndl-primary);
                height: unset;
                width: unset;
            }

            @media only screen and (max-width: 768px) {
                .mat-icon {
                    font-size: sizes.pxToRem(120);
                }
            }
        }
    }

    &:not(.ndl-modal-fullscreen) {
        .ndl-modal-content {
            padding: sizes.$ndl-padding-md;
            z-index: 1;
            &.ndl-modal-has-icon {
                @media only screen and (max-width: 300px) {
                    padding-right: sizes.pxToRem(100);
                }
            }
        }
    }

    &.ndl-modal-fullscreen {
        .mat-dialog-container {
            width: 100%;
            height: 100%;
        }

        .ndl-modal-content {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            overflow: auto;
        }
    }

    .ndl-modal-close {
        position: absolute;
        top: sizes.pxToRem(5);
        right: sizes.pxToRem(5);
        z-index: 2;
    }
}
