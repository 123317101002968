@use "variables/ndl-sizes" as sizes;
@use "ndl-theming" as theming;
@use "variables/ndl-colors" as colors;
@use "variables/ndl-typography" as typo;

.ndl-button {
    display: inline-block;
    max-width: 100%;
    border: none;
    padding: 0;
    background: none;
    font-size: typo.$defaultFontSize;
    line-height: typo.$defaultLineHeight;

    .ndl-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 100%;
        padding: sizes.$ndl-padding-xxs sizes.$ndl-padding-xxl;

        .ndl-button-suffix {
            order: 1;
            padding-left: sizes.$ndl-padding-md;
            margin-left: auto;
        }

        .ndl-button-prefix {
            order: -1;
            padding-right: sizes.$ndl-padding-md;
            margin-right: auto;
        }

        .ndl-button-prefix, .ndl-button-suffix {
            min-width: 3.1rem;
            display: inline-flex;
            align-items: center;
            font-size: sizes.pxToRem(21);
        }

        transition: background-color 0.3s, outline-color 0.3s;
        @include theming.ndl-block-theme('button', true, true, true);
    }

    &.ndl-button-radius .ndl-button-container {
        border-radius: sizes.$ndl-radius-sm;
    }

    &.ndl-button-large .ndl-button-container {
        padding: sizes.$ndl-padding-sm sizes.pxToRem(50);
    }

    @media screen and (min-width: 768px) {
        &.ndl-button-large-md .ndl-button-container {
            padding: sizes.$ndl-padding-sm sizes.pxToRem(50);
        }
    }

    &.ndl-button-small .ndl-button-container {
        padding: sizes.pxToRem(2) sizes.$ndl-padding-sm;

        &.ndl-button-radius {
            border-radius: sizes.$ndl-radius-xs;
        }

        .ndl-button-suffix {
            padding-left: sizes.$ndl-padding-xs;
        }

        .ndl-button-prefix {
            padding-right: sizes.$ndl-padding-xs;
        }

        .ndl-button-prefix, .ndl-button-suffix {
            min-width: unset;
        }
    }

    &.ndl-button-disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &.ndl-button-block {
        width: 100%;
    }
}
