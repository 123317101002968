@use "variables/ndl-colors" as colors;
@use "variables/ndl-shadows" as shadows;
@use "variables/ndl-sizes" as sizes;
@use "ndl-theming" as theming;

.ndl-collapse {
    display: block;
    overflow: hidden;
    border-radius: sizes.$ndl-radius-md;
    box-shadow: shadows.$ndl-box-shadow-1;
    margin-bottom: sizes.$ndl-margin-sm;

    .ndl-collapse-header {
        border-radius: sizes.$ndl-radius-md;
        &:not(.ndl-collapse-header-disabled) {
            cursor: pointer;
        }

        .ndl-collapse-header-container {
            display: flex;
            padding: sizes.$ndl-padding-md;
            align-items: center;

            .ndl-collapse-icon {
                margin-right: sizes.$ndl-margin-md;
                transition: transform 0.2s ease-out;

                &.after {
                    margin-right: 0;
                }
            }

            .ndl-collapse-header-content {
                flex: 1;
                width: 100%;
            }
        }

        @include theming.ndl-block-theme('collapse-header', true, false, true);
    }

    .ndl-collapse-body {
        .ndl-collapse-body-container {
            padding: sizes.$ndl-padding-md;
        }
    }

    &.ndl-collapse-opened {
        .ndl-collapse-icon {
            transform: rotate(-180deg);
        }
    }
}
