@import 'bootstrap-override/wb-variables';

.u-text-line-bg {
  position: relative;
  z-index:2;

  background-image: linear-gradient(to right, #FFBA7A 0%, #FFBA7A 100%);
  background-repeat: repeat-x; // make it horizontal
  background-position: 0 95%; // change the second value to adjust how far from the top the underline should be
  background-size: 100% 48%; // change the second value to the desired height of the underline

  &:before, &:after {
    content: '';
    display: inline-block;
    height: 1px;
  }

  &.content-padding{
    &:before, &:after {
      display:none;
    }
    padding:0 15px;
  }


    &--purple {
    background-image: linear-gradient(to right, #402AA3 0%, #402AA3 100%);
  }

  &--deep-purple {
    background-image: linear-gradient(to right, #C5C5F6 0%, #C5C5F6 100%);
  }

  &--green {
    background-image: linear-gradient(to right, #289E41 0%, #289E41 100%);

  }

  &--green-light {
    background-image: linear-gradient(to right, #CDE8A7 0%, #CDE8A7 100%);
  }

  &--orange-light {
    background-image: linear-gradient(to right, #ffa487 0%, #ffa487 100%);
  }

  &--purple-light {
    background-image: linear-gradient(to right, #bb6693 0%, #bb6693 100%);
  }

  &--blue-light {
    background-image: linear-gradient(to right, #87e0db 0%, #87e0db 100%);
  }

  &--blue {
    color: $blue-dark;
    background: #C9EAFF;
  }
}

