@use "ndl-theming" as theming;
@use "variables/ndl-colors" as colors;
@use "variables/ndl-sizes" as sizes;
@use "variables/ndl-shadows" as shadows;

$iconSize: sizes.pxToRem(80);

.ndl-banner {
    display: flex;
    position: relative;
    align-items: center;
    padding: sizes.$ndl-padding-md;
    margin-bottom: sizes.$ndl-margin-md;

    &.ndl-banner-closed {
        display: none;
    }

    &-radius {
        border-radius: sizes.$ndl-radius-sm;
    }

    &-has-icon {
        .ndl-banner-suffix {
            padding-right: $iconSize*0.9 - sizes.$ndl-padding-md;
        }
    }

    &-icon {
        display: flex;
        position: absolute;
        opacity: 50%;
        z-index: 0;
        margin: auto;
        right: sizes.$ndl-padding-md;

        mat-icon {
            height: $iconSize;
            width: $iconSize;
            font-size: $iconSize;
        }
    }

    & &-content {
        z-index: 1;
        flex: 1;
        padding: 0 sizes.$ndl-padding-md;
    }

    & &-close {
        position: absolute;
        top: sizes.$ndl-padding-xxs;
        right: sizes.$ndl-padding-xxs;
        opacity: 0.5;
        width: sizes.pxToRem(24);
        height: sizes.pxToRem(24);
        line-height: sizes.pxToRem(24);
        transition: opacity 0.3s ease-out;

        &:hover {
            opacity: 1;
        }
    }

    & &-title {
        margin-bottom: sizes.pxToRem(2);
    }

    & &-prefix, & &-suffix {
        z-index: 1;
        display: flex;
    }

    & &-prefix {
        align-self: flex-start;
    }

    &.ndl-banner-mobile {
        .ndl-banner-icon {
            display: none;
        }

        .ndl-banner-mobile-suffix {
            margin-top: sizes.$ndl-margin-md;

            .ndl-button {
                width: 100%;
            }
        }
    }

    @include theming.ndl-block-theme("banner", false, false, false, true);
}
