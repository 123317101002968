@import "../../variables/ndl-colors";

/**
 * This palette was generated with the tool provided by Material Design spec team:
 * https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 */
$ndl-pink-50: #fee3e9;
$ndl-pink-100: #fcb9c9;
$ndl-pink-200: #f98ca6;
$ndl-pink-300: #f55d83;
$ndl-pink-400: #f03869;
$ndl-pink-500: $ndl-red;
$ndl-pink-600: #dc054e;
$ndl-pink-700: #c6004b;
$ndl-pink-800: #b30049;
$ndl-pink-900: #8f0044;

$ndl-pink-palette : (
    50: $ndl-pink-50,
    100: $ndl-pink-100,
    200: $ndl-pink-200,
    300: $ndl-pink-300,
    400: $ndl-pink-400,
    500: $ndl-pink-500,
    600: $ndl-pink-600,
    700: $ndl-pink-700,
    800: $ndl-pink-800,
    900: $ndl-pink-900,
    A100: $ndl-pink-100,
    A200: $ndl-pink-200,
    A400: $ndl-pink-400,
    A700: $ndl-pink-700,
    contrast: (
        50: $ndl-extra-dark-blue,
        100:  $ndl-extra-dark-blue,
        200:  $ndl-extra-dark-blue,
        300:  $ndl-white,
        400:  $ndl-white,
        500:  $ndl-white,
        600:  $ndl-white,
        700:  $ndl-white,
        800:  $ndl-white,
        900:  $ndl-white,
        A100:  $ndl-extra-dark-blue,
        A200:  $ndl-extra-dark-blue,
        A400:  $ndl-white,
        A700:  $ndl-white,
    )
);
