@use "variables/ndl-colors" as colors;
@use "material/material-palettes" as palettes;
@use "variables/ndl-sizes" as sizes;
@use "sass:color" as c;
@use "sass:map" as m;

$ndl-primary-palette: palettes.$ndl-green-palette;
$ndl-accent-palette: palettes.$ndl-blue-palette;
$ndl-warn-palette: palettes.$ndl-pink-palette;
$ndl-primary-hue: 400;
$ndl-accent-hue: 900;
$ndl-warn-hue: 500;
$ndl-neutral: colors.$ndl-dark-grey;
$ndl-info: colors.$ndl-blue;
$ndl-success: colors.$ndl-light-green;
$ndl-danger: colors.$ndl-red;
$ndl-warning: colors.$ndl-orange;

$ndl-themes: (
    primary: (
        main: var(--ndl-primary),
        hover: var(--ndl-primary-hover),
        shadow: var(--ndl-primary-shadow)
    ),
    secondary: (
        main: var(--ndl-accent),
        hover: var(--ndl-accent-hover),
        shadow: var(--ndl-accent-shadow)
    ),
    neutral: (
        main: $ndl-neutral,
        hover: c.adjust($ndl-neutral, $lightness: -10%),
        shadow: #{$ndl-neutral+"1E"}
    ),
    info: (
        main: $ndl-info,
        hover: c.adjust($ndl-info, $lightness: -10%),
        shadow: #{$ndl-info+"1E"}
    ),
    success: (
        main: $ndl-success,
        hover: c.adjust($ndl-success, $lightness: -10%),
        shadow: #{$ndl-success+"1E"}
    ),
    warning: (
        main: $ndl-warning,
        hover: c.adjust($ndl-warning, $lightness: -10%),
        shadow: #{$ndl-warning+"1E"}
    ),
    danger: (
        main: $ndl-danger,
        hover: c.adjust($ndl-danger, $lightness: -10%),
        shadow: #{$ndl-danger+"1E"}
    )
);

@mixin ndl-primary() {
    background-color: var(--ndl-primary);
    color: colors.$ndl-white;
}
@mixin ndl-secondary() {
    background-color: var(--ndl-accent);
    color: colors.$ndl-white;
}
@mixin ndl-info() {
    background-color: $ndl-info;
    color: colors.$ndl-white;
}
@mixin ndl-success() {
    background-color: $ndl-success;
    color: colors.$ndl-white;
}
@mixin ndl-warning() {
    background-color: $ndl-warning;
    color: colors.$ndl-white;
}
@mixin ndl-danger() {
    background-color: $ndl-danger;
    color: colors.$ndl-white;
}
@mixin ndl-neutral() {
    background-color: $ndl-neutral;
    color: colors.$ndl-white;
}

@mixin ndl-primary-text() {
    color: var(--ndl-primary);
}
@mixin ndl-secondary-text() {
    color: var(--ndl-accent);
}
@mixin ndl-info-text() {
    color: $ndl-info;
}
@mixin ndl-success-text() {
    color: $ndl-success;
}
@mixin ndl-warning-text() {
    color: $ndl-warning;
}
@mixin ndl-danger-text() {
    color: $ndl-danger;
}
@mixin ndl-neutral-text() {
    color: $ndl-neutral;
}
@mixin ndl-dark-text() {
    color: colors.$ndl-extra-dark-blue;
}

@mixin ndl-primary-border() {
    color: var(--ndl-primary);
}
@mixin ndl-secondary-border() {
    color: var(--ndl-accent);
}
@mixin ndl-info-border() {
    border-color: $ndl-info;
}
@mixin ndl-success-border() {
    border-color: $ndl-success;
}
@mixin ndl-warning-border() {
    border-color: $ndl-warning;
}
@mixin ndl-danger-border() {
    border-color: $ndl-danger;
}
@mixin ndl-neutral-border() {
    border-color: $ndl-neutral;
}

@mixin ndl-block-theme($name, $hovering: false, $focus: false, $textSameAsOutlined: false, $buttonOverride: false) {
    &:not(.ndl-#{$name}-outlined):not(.ndl-#{$name}-default) {
        color: colors.$ndl-white;

        @if $buttonOverride {
            .ndl-button .ndl-button-container,.ndl-button:hover .ndl-button-container,.ndl-button:focus .ndl-button-container {
                background-color: colors.$ndl-white!important;
                border-color: transparent!important;
                outline: transparent!important;
                box-shadow: none!important;
            }
        }
    }

    &.ndl-#{$name}-default {
        background-color: colors.$ndl-white;

        &.ndl-#{$name}-outlined {
            outline: sizes.$ndl-border-md solid colors.$ndl-light-grey;
            outline-offset: -(sizes.$ndl-border-md);
            background-color: colors.$ndl-white;
        }

        .ndl-#{$name}-prefix .mat-icon {
            color: var(--ndl-primary);
        }

        &.ndl-#{$name}-borderless {
            outline: none;
        }
    }

    @each $theme, $color in $ndl-themes {
        &.ndl-#{$name}-#{$theme} {
            background-color: m.get($color, "main");

            &.ndl-#{$name}-outlined {
                outline: sizes.$ndl-border-md solid m.get($color, "main");
                outline-offset: -(sizes.$ndl-border-md);
                background-color: colors.$ndl-white;

                .ndl-#{$name}-prefix .mat-icon {
                    color: m.get($color, "main");
                }

                @if $textSameAsOutlined {
                    color: m.get($color, "main");

                    @if $hovering {
                        &:hover:not(:disabled):not(.ndl-#{$name}-disabled) {
                            color: m.get($color, "hover");
                        }
                    }
                }
            }

            &.ndl-#{$name}-borderless {
                outline: none;
            }

            @if $buttonOverride {
                &:not(.ndl-#{$name}-outlined) {
                    .ndl-button .ndl-button-container {
                        color: m.get($color, "main");
                    }
                }
            }

            @if $hovering {
                &:not(:disabled):not(.ndl-#{$name}-disabled) {
                    &:not(.ndl-#{$name}-outlined):hover {
                        background-color: m.get($color, "hover");
                    }
                    &.ndl-#{$name}-outlined:hover {
                        outline-color: m.get($color, "hover");
                    }
                    &.ndl-#{$name}-borderless {
                        outline: none;
                    }
                }
            }

            @if $focus {
                &:not(:disabled):not(.ndl-#{$name}-disabled):focus {
                    box-shadow: 0 0 0 0.2rem m.get($color, "shadow");
                }
            }
        }
    }

    &.ndl-#{$name}-light {
        background-color: colors.$ndl-dark-grey;

        &.ndl-#{$name}-outlined {
            border: sizes.$ndl-border-md solid colors.$ndl-dark-grey;
            background-color: colors.$ndl-white;
            .ndl-#{$name}-prefix .mat-icon {
                color: colors.$ndl-dark-grey;
            }
        }
        &.ndl-#{$name}-borderless {
            border: none;
        }

        @if $buttonOverride {
            &:not(.ndl-#{$name}-outlined) {
                .btn.ndl-button.btn-ndl-green {
                    outline: colors.$ndl-white;
                    color: colors.$ndl-dark-grey;
                }
            }
        }
    }
}
