@use 'variables/ndl-colors' as color;

.app-loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    text-align: center;
    z-index: 99;

    &.root {
        animation: appearTopFirst 0.6s cubic-bezier(0.000, 0.515, 0.125, 1.65),
                    appearTopSecond 0.2s ease-out 0.62s;
        background: color.$ndl-extra-light-grey;
    }

    .wrapper {
        position: absolute;
        top:50%;
        margin-top:-120px;
        width: 100%;
        vertical-align: middle;
        text-align: center;
        padding-top: 20px;


        .sub-wrapper {
            position: relative;
            width: 200px;
            height: 200px;
            margin: auto;
            border-radius: 50%;

            .spinner, .background {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                margin: 0;
                border-radius: 50%;
            }

            .spinner {
                border: 7px solid;
                border-top-color: var(--ndl-primary);
                border-right-color:  var(--ndl-primary-hover);
                border-bottom-color: var(--ndl-primary);
                border-left-color: var(--ndl-primary-hover);
                opacity: 0.8;
                box-sizing: border-box;
                animation: spin 1s linear infinite;
                z-index: 10;
                box-shadow: 0 0 23px -6px rgba(0, 0, 0, 1);
            }

            .background {
                animation: glow 1s ease-in-out infinite alternate;
                background: white url("~assetsFiles/needl-n.svg") no-repeat center;
                background-size: 50%;
                z-index: 9;
            }
        }
    }
    .blur {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(color.$ndl-extra-light-grey, 0.95);
        backdrop-filter: blur(6px);
    }
}


@keyframes appearTopFirst {
    0% { transform: translateY(100%); }
    100% { transform: translateY(0); }
}
@keyframes appearTopSecond {
    0% { transform: translateY(0); }
    50% { transform: translateY(-3%); }
    100% { transform: translateY(0); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes glow {
    0% { opacity: 0.4; }
    100% { opacity: 0.8; }
}

@keyframes bulb {
    0% { border-width: 8px; }
    100% { border-width: 18px; }
}
