@import "../../variables/ndl-colors";

/**
 * This palette was generated with the tool provided by Material Design spec team:
 * https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 */
$ndl-blue-50: #e4e7ec;
$ndl-blue-100: #bbc2d2;
$ndl-blue-200: #8f9bb3;
$ndl-blue-300: #667595;
$ndl-blue-400: #475a81;
$ndl-blue-500: #27406f;
$ndl-blue-600: #213967;
$ndl-blue-700: #1a315d;
$ndl-blue-800: #132850;
$ndl-blue-900: $ndl-extra-dark-blue;

$ndl-blue-palette : (
    50: $ndl-blue-50,
    100: $ndl-blue-100,
    200: $ndl-blue-200,
    300: $ndl-blue-300,
    400: $ndl-blue-400,
    500: $ndl-blue-500,
    600: $ndl-blue-600,
    700: $ndl-blue-700,
    800: $ndl-blue-800,
    900: $ndl-blue-900,
    A100: $ndl-blue-100,
    A200: $ndl-blue-200,
    A400: $ndl-blue-400,
    A700: $ndl-blue-700,
    contrast: (
        50: $ndl-extra-dark-blue,
        100:  $ndl-extra-dark-blue,
        200:  $ndl-extra-dark-blue,
        300:  $ndl-white,
        400:  $ndl-white,
        500:  $ndl-white,
        600:  $ndl-white,
        700:  $ndl-white,
        800:  $ndl-white,
        900:  $ndl-white,
        A100:  $ndl-extra-dark-blue,
        A200:  $ndl-extra-dark-blue,
        A400:  $ndl-white,
        A700:  $ndl-white,
    )
);
