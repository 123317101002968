.ndl-form {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        opacity: 0.8;
        font-size: 15px;
        color: rgba(29, 51, 67, 0.50);
        letter-spacing: 0;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        opacity: 0.8;
        font-size: 15px;
        color: rgba(29, 51, 67, 0.50);
        letter-spacing: 0;
    }

    :-ms-input-placeholder { /* IE 10+ */
        opacity: 0.8;
        font-size: 15px;
        color: rgba(29, 51, 67, 0.50);
        letter-spacing: 0;
    }

    :-moz-placeholder { /* Firefox 18- */
        opacity: 0.8;
        font-size: 15px;
        color: rgba(29, 51, 67, 0.50);
        letter-spacing: 0;
    }
}
