@import "../../variables/ndl-colors";

/**
 * This palette was generated with the tool provided by Material Design spec team:
 * https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 */
$ndl-red-50: #ffe9ed;
$ndl-red-100: #ffc7ce;
$ndl-red-200: #f29092;
$ndl-red-300: $ndl-light-red;
$ndl-red-400: #f13841;
$ndl-red-500: #f41c25;
$ndl-red-600: #e50525;
$ndl-red-700: #d30020;
$ndl-red-800: #c60018;
$ndl-red-900: #b6000b;

$ndl-red-palette : (
    50: $ndl-red-50,
    100: $ndl-red-100,
    200: $ndl-red-200,
    300: $ndl-red-300,
    400: $ndl-red-400,
    500: $ndl-red-500,
    600: $ndl-red-600,
    700: $ndl-red-700,
    800: $ndl-red-800,
    900: $ndl-red-900,
    A100: $ndl-red-100,
    A200: $ndl-red-200,
    A400: $ndl-red-400,
    A700: $ndl-red-700,
    contrast: (
        50: $ndl-black,
        100:  $ndl-black,
        200:  $ndl-black,
        300:  $ndl-black,
        400:  $ndl-white,
        500:  $ndl-white,
        600:  $ndl-white,
        700:  $ndl-white,
        800:  $ndl-white,
        900:  $ndl-white,
        A100:  $ndl-black,
        A200:  $ndl-black,
        A400:  $ndl-white,
        A700:  $ndl-white,
    )
);
