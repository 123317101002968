@use "sass:math" as math;
@use "~@angular/material" as mat;
@use "variables/ndl-sizes" as sizes;
@use "variables/ndl-colors" as colors;

$stepMargin: sizes.pxToRem(2);
$bulletBorder: 0;
$bulletPadding: 0;
$bulletSize: sizes.pxToRem(28);
$bulletInnerSize: $bulletSize - $bulletBorder * 2;
$dividerSize: sizes.$ndl-border-md;
$dividerMargin: math.div($bulletSize, 2) - math.div($dividerSize, 2) + $stepMargin;

.ndl-stepper {
    display: flex;
    height: 100%;
    padding: sizes.$ndl-padding-xxs;

    .mat-drawer-container {
        flex-grow: 1;
    }
}

.ndl-step-icon {
    order: 2;
}

.ndl-step-label {
    order: 1;
    text-align: center;
}
.ndl-step-icon {
    margin-bottom: 26px;
    width: 20px!important;
    height: 20px!important;
}

.ndl-step-icon-wrapper-selected {
    position: absolute;
    width: 26px;
    height: 26px;
    border: 1px solid var(--ndl-primary);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ndl-step-header {
    flex-direction: column;
    height: unset!important;
}

.ndl-stepper-line-selected {
    border-color: var(--ndl-primary);
}

.ndl-stepper-line {
    padding-bottom: 57px;
}

.ndl-step-text-label {
    font-size: 16px;
    padding-bottom: 5px;
}

.ndl-stepper-container {
    align-items: flex-end!important;
    padding: 0 24px;
}
.ndl-stepper {
    .mat-list-item {
        height: 48px!important;
        &.ndl-step-selected {
            color: var(--ndl-primary)!important;
            border-radius: 10px;
            background: rgba(0,0,0, 0.05);
            .mat-line {
                font-weight: bold;
            }
        }
        &.ndl-step-disabled {
            background-color: unset;
            opacity: 0.5;
        }
        &.mat-list-single-selected-option:not(.ndl-step-selected) {
            background: unset!important;
        }
        .mat-ripple {
            border-radius: 10px;
        }
    }
    .ndl-horizontal-icon {
        width: 20px!important;
        height: 20px!important;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background: var(--ndl-primary);
        font-size: 16px!important;
        line-height: 20px;
        order: 1;
        padding: 2px!important;
        position: relative;
        visibility: hidden;

        &.selected {
            visibility: visible;
        }

        .mat-icon {
            color: white;
            text-align: center;
        }
    }

    .mat-list-text {
        order: 2;
        padding-left: 0 !important;
    }
}

.ndl-stepper-new {
    .ndl-stepper {
        display: flex;
        flex: 1 1;
        margin-bottom: sizes.$ndl-margin-sm;
        overflow: hidden;

        .ndl-step {
            flex: 1 0 0;

            &-bullet {
                display: flex;

                &-container {
                    margin: $stepMargin sizes.$ndl-margin-sm;
                    position: relative;
                    width: $bulletSize;
                    height: $bulletSize;
                    padding: $bulletPadding;
                    border-radius: 50%;
                    border: $bulletBorder solid transparent;
                    color: colors.$ndl-white;

                    @media screen and (max-width: 1199px) {
                        margin: $stepMargin 0;
                    }

                    &-content {
                        display: flex;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background: colors.$ndl-light-grey;
                        text-align: center;
                        font-size: sizes.pxToRem(15);
                        justify-content: center;
                        align-items: center;
                        min-width: 25px;
                        min-height: 25px;
                    }
                }
            }

            &-label {
                color: #D9D9D9;
                text-align: center;

                @media screen and (max-width: 1199px) {
                    display: none;
                }
            }

            &.selected {
                .ndl-step-bullet-container {
                    border-color: var(--ndl-primary);
                }

                .ndl-step-label {
                    color: colors.$ndl-black;
                }
            }

            &.done, &.selected {
                .ndl-step-bullet-container-content {
                    background: var(--ndl-primary);
                }

                .ndl-stepper-divider-right {
                    border-color: var(--ndl-primary);
                }
            }

            &.done {
                .ndl-stepper-divider-left {
                    border-color: var(--ndl-primary);
                }
            }

        }

        &-divider {
            &, &-left, &-right {
                height: 0;
                border: $dividerSize solid colors.$ndl-light-grey;
                margin-top: $dividerMargin;
            }

            &-left, &-right {
                flex: 1 1;

                &.none {
                    visibility: hidden;
                }
            }

            &-right {
                border-radius: 0 sizes.$ndl-radius-sm sizes.$ndl-radius-sm 0;
            }

            &-left {
                border-radius: sizes.$ndl-radius-sm 0 0 sizes.$ndl-radius-sm;
            }

            &.done {
                border-color: var(--ndl-primary);
            }
        }

        &-content {
            outline: 0;

            &[aria-expanded='false'] {
                height: 0;
                overflow: hidden;
            }
        }
    }
}
