@use "variables/ndl-colors" as color;
@use "variables/ndl-typography" as typography;
@use "variables/ndl-sizes" as sizes;

html, body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

html {
    font-size: sizes.$ndl-base-px;
    font-family: typography.$nunito;
    color: var(--ndl-accent);
}

body {
    display: flex;

    background-color: color.$ndl-extra-light-grey;

    & > * {
        flex-grow: 1;
    }
}

a, a:not([href]):not([class]) {
    color: var(--ndl-primary);

    &:hover {
        color: var(--ndl-primary);
    }
}

h1 {
    @include typography.ndl-h1;
    margin-bottom: sizes.$ndl-margin-xxl;
}
h2 {
    @include typography.ndl-h2;
    margin-bottom: sizes.$ndl-margin-xl;
}
h3 {
    @include typography.ndl-h3;
}
h4 {
    @include typography.ndl-h4;
}
h5 {
    @include typography.ndl-h5;
}
h6 {
    @include typography.ndl-h6;
}
h1, h2, h3, h4, h5, h6 {
    display: flex;
}

small {
    @include typography.ndl-small;
}
