.ndl-tabs {
    .mat-tab-label {
        font-size: 18px;

        &-active {
            color: var(--ndl-primary) !important;
            font-weight: bold;
        }
    }
}

